$path_fonts: "../assets/fonts";
/* roboto-300 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('#{$path_fonts}/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$path_fonts}/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$path_fonts}/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$path_fonts}/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
    url('#{$path_fonts}/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$path_fonts}/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('#{$path_fonts}/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$path_fonts}/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$path_fonts}/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$path_fonts}/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('#{$path_fonts}/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$path_fonts}/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('#{$path_fonts}/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$path_fonts}/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$path_fonts}/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$path_fonts}/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
    url('#{$path_fonts}/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$path_fonts}/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('#{$path_fonts}/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$path_fonts}/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$path_fonts}/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$path_fonts}/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
    url('#{$path_fonts}/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$path_fonts}/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Minion Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Minion Pro Regular'), url('#{$path_fonts}/MinionPro-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Minion Pro Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('Minion Pro Semibold'), url('#{$path_fonts}/MinionPro-Semibold.woff') format('woff');
}
@font-face {
    font-family: 'Minion Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Minion Pro Bold'), url('#{$path_fonts}/MinionPro-Bold.woff') format('woff');
}
@font-face {
    font-family: 'Trajan Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Trajan Pro Regular'), url('#{$path_fonts}/TrajanPro-Regular.woff') format('woff');
}


@font-face {
    font-family: 'Trajan Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Trajan Pro Bold'), url('#{$path_fonts}/TrajanPro-Bold.woff') format('woff');
}