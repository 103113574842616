@import 'reset';
@import 'fonts';

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Minion Pro Regular', sans-serif;
}
.MuiContainer-fixed {
  max-width: 1440px !important;
}
